import { ProcessLog } from '@/found/modules';
import request from '../../../../../../utils/request';
import BasePage from '../../../../../../components/table_page';
import Form from '../form';

export default {
  name: 'annual_fuzzy_award',
  extends: BasePage,
  data() {
    return {
      params: {

      },
    };
  },
  components: {
    Form,
    ProcessLog,
  },
  created() {
    this.getConfigList('tpm_annual_fuzzy_award_list');
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      // 审批通过 approved
      // 审批中 approving
      // 活动关闭 closed
      // 流程追回 interrupt
      // 驳回 rejected
      const { approveStatus, processCode } = row;
      if (approveStatus && code === 'edit' && (approveStatus !== 'rejected')) {
        return false;
      }
      if (code === 'process_log' && !processCode) {
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      } else if (code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row: { processNo: row.processCode },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessLog';
        this.openDrawer();
      }
    },
    // 保存回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };

      this.$emit('approve', configParams);
    },
  },
};
